import React, { useState, useMemo } from 'react';
import ProfileSection from '../../components/profileSection/ProfileSection';
import MonitorCarousel from '../../components/monitorCarousel/MonitorCarousel';
import ChatWindow from '../../components/chatWindow/ChatWindow';
import BottomIcons from '../../components/bottomIcons/BottomIcons';
import { useAppContext } from '../../context/AppContext';
import './HomePage.css';

const HomePage = () => {
    const { balance, updateBalance } = useAppContext();
    const [chatInput, setChatInput] = useState('');

    const windows = useMemo(() => [
        { 
            id: 1, 
            title: 'Machine Lab', 
            content: '💻 Upgrade your rig to boost bot speed, defense, and attack power.',
            icon: '💻'
        },
        { id: 2, title: 'Financial Terminal', content: '📊 Manage all assets, liabilities, cash flow, and insurance policies.', icon: '📊' },
        { id: 3, title: 'R&D Center', content: '🔬 Unlock new technologies to enhance bots and gain strategic bonuses.', icon: '🔬' },
        { id: 4, title: 'Command Center', content: '🖥️ Store, manage, and deploy your digital hack bot army for cyber operations.', icon: '🖥️' },
        { id: 5, title: 'System Logs', content: '🗂 Track top players, achievements, kills, and other competitive stats.', icon: '🗂' },
        { id: 6, title: 'Data Vault', content: '🗄 Save key locations, track favorite players, and store important data.', icon: '🗄' },
        { id: 7, title: 'Comm Center', content: '✉️ Send and receive in-game mail to stay connected with other players.', icon: '✉️' },
        { id: 8, title: 'Search', content: '🔍 Quickly find players, messages, locations, or other data.', icon: '🔍' },
        { id: 9, title: 'Marketplace', content: '📦 Trade goods for cash or buy items from other players.', icon: '📦' },
        { id: 10, title: 'Investment Portfolio', content: '📈 Discover evolving opportunities to grow wealth over time.', icon: '📈' },
        { id: 11, title: 'The Bank', content: '💰 Take out loans or manage lines of credit for upgrades and purchases.', icon: '💰' },
        { id: 12, title: 'Contracts', content: '📜 Complete missions and contracts to earn cash and rare items.', icon: '📜' },
        { id: 13, title: 'Black Market', content: '🌑 Access rare, high-risk purchases during special promotions.', icon: '🌑' },
        { id: 14, title: 'Government Hub', content: '🏛 Learn about current in-game interest rates, inflation, and policies.', icon: '🏛' },
    ], []);

    const handleChatChange = (e) => {
        setChatInput(e.target.value);
    };

    return (
        <div className="HomePage">
            <ProfileSection balance={balance} />
            <MonitorCarousel windows={windows} />
            <ChatWindow
                chatInput={chatInput}
                handleChatChange={handleChatChange}
            />
            <BottomIcons updateBalance={updateBalance} />
        </div>
    );
};

export default HomePage;
