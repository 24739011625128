import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar/Avatar';
import './ProfileSection.css';

const ProfileSection = ({ balance, avatarSrc }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [playerStats] = useState({
        level: 1,
        exp: 0,
        rank: 'Rookie',
        reputation: 0
    });

    // Simulate loading state
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="profile-section">
                <div className="loading-placeholder" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
                <div className="financial-info">
                    <div className="loading-placeholder loading-text" />
                    <div className="loading-placeholder loading-text" style={{ width: '60px', marginTop: '8px' }} />
                </div>
            </div>
        );
    }

    const formatBalance = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(amount);
    };

    return (
        <div className="profile-section">
            <Avatar src={avatarSrc} alt="Player Avatar" />
            <div className="financial-info">
                <h2>{formatBalance(balance)}</h2>
                <p>Level {playerStats.level}</p>
            </div>
            <div className="stats-container">
                <div className="stat-item">
                    <div className="stat-label">EXP</div>
                    <div className="stat-value">{playerStats.exp}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-label">Rank</div>
                    <div className="stat-value">{playerStats.rank}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-label">Rep</div>
                    <div className="stat-value">{playerStats.reputation}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-label">Bots</div>
                    <div className="stat-value">0</div>
                </div>
            </div>
        </div>
    );
};

ProfileSection.propTypes = {
    balance: PropTypes.number.isRequired,
    avatarSrc: PropTypes.string
};

export default React.memo(ProfileSection);
