import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const BotContext = createContext();

export const BotProvider = ({ children }) => {
  const [bots, setBots] = useState(() => {
    const storedBots = localStorage.getItem('bots');
    return storedBots ? JSON.parse(storedBots) : {
      total: 0,
      types: {
        infiltrators: 0,
        defenders: 0,
        analysts: 0
      }
    };
  });

  const [buildingState, setBuildingState] = useState(() => {
    const storedState = localStorage.getItem('buildingState');
    return storedState ? JSON.parse(storedState) : {
      isBuilding: false,
      endTime: null,
      botCount: 0,
      botType: null
    };
  });

  useEffect(() => {
    localStorage.setItem('bots', JSON.stringify(bots));
  }, [bots]);

  useEffect(() => {
    localStorage.setItem('buildingState', JSON.stringify(buildingState));
  }, [buildingState]);

  const buildBots = useCallback((count, type) => {
    setBots(prevBots => ({
      ...prevBots,
      total: prevBots.total + count,
      types: {
        ...prevBots.types,
        [type]: prevBots.types[type] + count
      }
    }));
  }, []);

  const startBuilding = useCallback((count, type) => {
    const endTime = new Date().getTime() + (count * 1000);
    setBuildingState({
      isBuilding: true,
      endTime,
      botCount: count,
      botType: type
    });
  }, []);

  const finishBuilding = useCallback(() => {
    setBuildingState(prevState => {
      if (prevState.isBuilding) {
        buildBots(prevState.botCount, prevState.botType);
        return {
          isBuilding: false,
          endTime: null,
          botCount: 0,
          botType: null
        };
      }
      return prevState;
    });
  }, [buildBots]);

  return (
    <BotContext.Provider value={{ bots, buildBots, buildingState, startBuilding, finishBuilding }}>
      {children}
    </BotContext.Provider>
  );
};

export const useBotContext = () => {
  const context = useContext(BotContext);
  if (context === undefined) {
    throw new Error('useBotContext must be used within a BotProvider');
  }
  return context;
};
