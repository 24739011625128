import React, { createContext, useContext } from 'react';
import { usePersistedBalance } from '../hooks/usePersistedBalance';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [balance, updateBalance] = usePersistedBalance();

  return (
    <AppContext.Provider value={{ balance, updateBalance }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
