import { useState, useEffect, useCallback } from 'react';

export const usePersistedBalance = (initialBalance = 110) => {
  const [balance, setBalance] = useState(() => {
    const storedBalance = localStorage.getItem('balance');
    return storedBalance ? parseFloat(storedBalance) : initialBalance;
  });

  useEffect(() => {
    localStorage.setItem('balance', balance.toString());
  }, [balance]);

  const updateBalance = useCallback((amount) => {
    setBalance(prevBalance => {
      const newBalance = prevBalance + amount;
      if (newBalance < 0) {
        alert("Insufficient funds!");
        return prevBalance;
      }
      return newBalance;
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateBalance(10);
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [updateBalance]);

  return [balance, updateBalance];
};
