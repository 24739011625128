import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Monitor from '../monitor/Monitor';
import './MonitorCarousel.css';

const MonitorCarousel = ({ windows }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const autoScrollRef = useRef(null);

    const handleDragStart = useCallback((e) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
        carouselRef.current.style.cursor = 'grabbing';
    }, []);

    const handleDragEnd = useCallback(() => {
        setIsDragging(false);
        carouselRef.current.style.cursor = 'grab';
    }, []);

    const handleDragMove = useCallback((e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        carouselRef.current.scrollLeft = scrollLeft - walk;
    }, [isDragging, startX, scrollLeft]);

    const handleWheel = useCallback((e) => {
        e.preventDefault();
        carouselRef.current.scrollLeft += e.deltaY;
    }, []);

    const scrollToIndex = useCallback((index) => {
        if (!carouselRef.current) return;
        
        const newIndex = Math.max(0, Math.min(index, windows.length - 1));
        const monitor = carouselRef.current.children[newIndex];
        
        if (monitor) {
            const scrollOptions = {
                left: monitor.offsetLeft - carouselRef.current.offsetWidth / 2 + monitor.offsetWidth / 2,
                behavior: 'smooth'
            };
            carouselRef.current.scrollTo(scrollOptions);
            setCurrentIndex(newIndex);
        }
    }, [windows.length]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowLeft') {
            scrollToIndex(currentIndex - 1);
        } else if (e.key === 'ArrowRight') {
            scrollToIndex(currentIndex + 1);
        }
    }, [currentIndex, scrollToIndex]);

    // Setup event listeners
    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('wheel', handleWheel, { passive: false });
            window.addEventListener('keydown', handleKeyDown);
            
            return () => {
                carousel.removeEventListener('wheel', handleWheel);
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [handleWheel, handleKeyDown]);

    // Auto-scroll functionality
    useEffect(() => {
        const carousel = carouselRef.current;
        if (!carousel) return;

        const startAutoScroll = () => {
            autoScrollRef.current = setInterval(() => {
                scrollToIndex((currentIndex + 1) % windows.length);
            }, 5000);
        };

        const stopAutoScroll = () => {
            if (autoScrollRef.current) {
                clearInterval(autoScrollRef.current);
            }
        };

        startAutoScroll();

        carousel.addEventListener('mouseenter', stopAutoScroll);
        carousel.addEventListener('mouseleave', startAutoScroll);

        return () => {
            stopAutoScroll();
            carousel.removeEventListener('mouseenter', stopAutoScroll);
            carousel.removeEventListener('mouseleave', startAutoScroll);
        };
    }, [currentIndex, windows.length, scrollToIndex]);

    return (
        <div 
            className="monitor-carousel"
            ref={carouselRef}
            onMouseDown={handleDragStart}
            onMouseLeave={handleDragEnd}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDragMove}
            role="region"
            aria-label="Monitor Carousel"
            tabIndex="0"
        >
            {windows.map((window) => (
                <div key={window.id} className="carousel-monitor">
                    <Monitor
                        title={window.title}
                        content={window.content}
                        id={window.id}
                        icon={window.icon}
                    />
                </div>
            ))}
        </div>
    );
};

MonitorCarousel.propTypes = {
    windows: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        icon: PropTypes.string
    })).isRequired
};

export default React.memo(MonitorCarousel);
