import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useBotContext } from '../../context/BotContext';
import './CommandCenter.css';

const CommandCenter = () => {
  const { bots } = useBotContext();
  const [title, setTitle] = useState('');
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isGlowing, setIsGlowing] = useState(false);
  const fullTitle = '> Command Center';

  useEffect(() => {
    let mounted = true;
    let index = 0;
    const intervalId = setInterval(() => {
      if (!mounted) return;
      
      if (index < fullTitle.length) {
        setTitle(prev => fullTitle.slice(0, index + 1));
        index++;
      } else {
        clearInterval(intervalId);
        setIsTypingDone(true);
        setTimeout(() => {
          if (mounted) setIsGlowing(true);
        }, 500);
      }
    }, 100);

    return () => {
      mounted = false;
      clearInterval(intervalId);
    };
  }, []);

  if (!bots?.types) {
    return (
      <div className="command-center">
        <div className="title-container">
          <h1 className="hacker-title">Initializing...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="command-center">
      <div className="title-container">
        <h1 className={`hacker-title ${isGlowing ? 'glow' : ''}`}>
          {title}
          {!isTypingDone && <span className="cursor">|</span>}
        </h1>
      </div>
      <div className="army-info">
        <h2>Army Information</h2>
        <p>Total Bots: {bots.total}</p>
        <p>Types of Units:</p>
        <ul>
          <li>🤖 Infiltrators: {bots.types.infiltrators}</li>
          <li>🛡️ Defenders: {bots.types.defenders}</li>
          <li>🔍 Analysts: {bots.types.analysts}</li>
        </ul>
      </div>
      <Link to="/" className="back-button">Return to Base</Link>
    </div>
  );
};

export default CommandCenter;
