import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ChatWindow.css';

const ChatWindow = ({ chatInput, handleChatChange }) => {
    const [messages, setMessages] = useState([
        { id: 1, user: 'System', text: 'Welcome to the Cyber Network!' },
        { id: 2, user: 'Guide', text: 'Type your message below to get started...' }
    ]);
    
    const chatLogRef = useRef(null);
    const inputRef = useRef(null);

    // Auto-scroll to bottom when new messages arrive
    useEffect(() => {
        if (chatLogRef.current) {
            chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (chatInput.trim()) {
            setMessages(prev => [
                ...prev,
                { 
                    id: Date.now(),
                    user: 'You',
                    text: chatInput.trim()
                }
            ]);
            handleChatChange({ target: { value: '' } }); // Clear input
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(e);
        }
    };

    return (
        <div className="chat-window">
            <div className="chat-log" ref={chatLogRef}>
                {messages.map(message => (
                    <div key={message.id} className="chat-message">
                        <strong>{message.user}:</strong> {message.text}
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="chat-input-container">
                <input
                    type="text"
                    className="chat-input"
                    placeholder="Enter your message..."
                    value={chatInput}
                    onChange={handleChatChange}
                    onKeyPress={handleKeyPress}
                    ref={inputRef}
                    aria-label="Chat message"
                />
                <button 
                    type="submit" 
                    className="send-button"
                    disabled={!chatInput.trim()}
                >
                    Send
                </button>
            </form>
        </div>
    );
};

ChatWindow.propTypes = {
    chatInput: PropTypes.string.isRequired,
    handleChatChange: PropTypes.func.isRequired
};

export default React.memo(ChatWindow);
