import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';
import CommandCenter from './pages/commandCenter/CommandCenter';
import { AppProvider } from './context/AppContext';
import { BotProvider } from './context/BotContext';
import './App.css';

const App = () => {
    return (
        <AppProvider>
            <BotProvider>
                <Router>
                    <div className="App">
                        <main className="main-container">
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/command-center" element={<CommandCenter />} />
                            </Routes>
                        </main>
                    </div>
                </Router>
            </BotProvider>
        </AppProvider>
    );
};

export default App;
