import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useBotContext } from '../../context/BotContext';
import './BottomIcons.css';

const BOT_COST = 3; // Cost per bot in dollars
const MAX_BOTS = 999;

const BottomIcons = () => {
    const { updateBalance } = useAppContext();
    const { buildingState, startBuilding, finishBuilding } = useBotContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [botCount, setBotCount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [buildTime, setBuildTime] = useState('');
    const [remainingTime, setRemainingTime] = useState('');
    const modalRef = useRef(null);

    // Calculate costs and build time when bot count changes
    useEffect(() => {
        setTotalCost(botCount * BOT_COST);
        const minutes = Math.floor(botCount / 60);
        const seconds = botCount % 60;
        setBuildTime(`${minutes}m ${seconds}s`);
    }, [botCount]);

    // Handle build timer
    useEffect(() => {
        let intervalId;
        if (buildingState.isBuilding) {
            const updateRemainingTime = () => {
                const now = new Date().getTime();
                const timeLeft = Math.max(0, buildingState.endTime - now);

                if (timeLeft <= 0) {
                    clearInterval(intervalId);
                    finishBuilding();
                    setRemainingTime('');
                } else {
                    const minutes = Math.floor(timeLeft / 60000);
                    const seconds = Math.ceil((timeLeft % 60000) / 1000);
                    setRemainingTime(`${minutes}m ${seconds}s`);
                }
            };

            updateRemainingTime();
            intervalId = setInterval(updateRemainingTime, 1000);
        }

        return () => clearInterval(intervalId);
    }, [buildingState, finishBuilding]);

    // Handle clicking outside modal
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        if (isModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);

    const handleBotCountChange = (e) => {
        const value = e.target.value;
        // Use a regular expression to allow only digits
        const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

        // If the numeric value exceeds 999, set it to 999
        if (numericValue.length > 3) {
            setBotCount('999'); // Set to max if more than 3 digits
        } else {
            // Set the bot count, ensuring it does not exceed 999
            const parsedValue = numericValue ? Math.min(parseInt(numericValue, 10), 999) : '';
            setBotCount(parsedValue.toString()); // Set the value directly without padding
        }
    };

    const handleBuildBots = useCallback(() => {
        if (botCount > 0 && !buildingState.isBuilding) {
            updateBalance(-totalCost);
            startBuilding(botCount, 'infiltrators');
            setIsModalOpen(false);
            setBotCount(0);
        }
    }, [botCount, buildingState.isBuilding, totalCost, updateBalance, startBuilding]);

    const handleOverlayClick = useCallback((e) => {
        if (e.target === e.currentTarget) {
            setIsModalOpen(false);
        }
    }, []);

    return (
        <>
            <div className="bottom-icons">
                <button 
                    className="icon-button" 
                    onClick={() => setIsModalOpen(true)}
                    disabled={buildingState.isBuilding}
                >
                    🤖 Build
                </button>
                <button className="icon-button">💰 Market</button>
                <button className="icon-button">⚔️ Battle</button>
                <button className="icon-button">👥 Crew</button>
            </div>

            {isModalOpen && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal-content" ref={modalRef}>
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>×</button>
                        <h2>Bot Builder</h2>
                        <div className="bot-input">
                            <button 
                                onClick={() => setBotCount(Math.max(0, Number(botCount) - 1))} 
                                disabled={buildingState.isBuilding}
                            >
                                -
                            </button>
                            <input 
                                type="text"
                                value={botCount}
                                onChange={handleBotCountChange}
                                onKeyPress={(e) => {
                                    // Prevent entering anything other than digits
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                maxLength="3"
                                disabled={buildingState.isBuilding}
                            />
                            <button 
                                onClick={() => setBotCount(Math.min(MAX_BOTS, Number(botCount) + 1))} 
                                disabled={buildingState.isBuilding}
                            >
                                +
                            </button>
                        </div>
                        <div className="bot-info">
                            <p>Total Cost: ${totalCost}</p>
                            <p>Build Time: {buildingState.isBuilding ? remainingTime : buildTime}</p>
                        </div>
                        <div className="build-button-container">
                            <button 
                                onClick={handleBuildBots} 
                                disabled={buildingState.isBuilding || botCount === 0}
                            >
                                {buildingState.isBuilding ? 'Building...' : 'Build Bots'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(BottomIcons);
