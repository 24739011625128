import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Monitor.css';

const Monitor = ({ title, content, id, icon }) => (
    <div className="monitor" role="article">
        <h3 className="monitor-title">
            {icon && <span className="monitor-icon" aria-hidden="true">{icon}</span>}
            {title}
        </h3>
        <p className="monitor-content">{content}</p>
        {id === 4 && (
            <Link 
                to="/command-center" 
                className="monitor-link"
                aria-label={`Enter ${title}`}
            >
                Enter Command Center
            </Link>
        )}
    </div>
);

Monitor.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    icon: PropTypes.string
};

export default React.memo(Monitor);
