import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.css';

const Avatar = ({ src, alt }) => (
    <img
        src={src || "https://via.placeholder.com/80"}
        alt={alt || "Profile Avatar"}
        className="avatar"
        loading="lazy"
    />
);

Avatar.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string
};

export default React.memo(Avatar);
